@font-face {
  font-family: 'Josefin Sans';
  src: local('Josefin Sans'), url(./Josefin_Sans/JosefinSans-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Blinker';
  src: url(./fonts/Blinker/Blinker-Regular.ttf);
}
@font-face {
  font-family: 'Blinker';
  src: url(./fonts/Blinker/Blinker-Thin.ttf);
  font-weight: 100;
}
@font-face {
  font-family: 'Blinker';
  src: url(./fonts/Blinker/Blinker-ExtraLight.ttf);
  font-weight: 200;
}
@font-face {
  font-family: 'Blinker';
  src: url(./fonts/Blinker/Blinker-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: 'Blinker';
  src: url(./fonts/Blinker/Blinker-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'Blinker';
  src: url(./fonts/Blinker/Blinker-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'Blinker';
  src: url(./fonts/Blinker/Blinker-ExtraBold.ttf);
  font-weight: 800;
}
@font-face {
  font-family: 'Blinker';
  src: url(./fonts/Blinker/Blinker-Black.ttf);
  font-weight: 900;
}

html {
  min-height: min(1000px, 100%);
  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;
  background-color: #000;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: 'Blinker';
  user-select: none;
}

#root {
  flex-grow: 1;
  min-height: 100%;

  display: flex;
  flex-direction: column;
}

.NewApp {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#logo, #footer, .photoview {
  width: calc(100vw - 16px);
}

#logo {
  height: auto;
  margin-top: min(80px, 9vw);
}

.logo-text {
  margin-left: auto;
  margin-bottom: min(90px, 9vw);
  margin-bottom: min(140px, 14vw);
  margin-right: min(30px, 3vw);
  margin-top: max(-5vw, -35px);
  font-size: min(5vw, 35px);
}

#footer {
  padding-bottom: 4px;
  font-family: Blinker;
  font-weight: 300;
  font-size: 16px;
  align-self: auto;
  margin-top: 40px;
  display: flex;
}

#footer-left {
  flex: 1;
  align-self: flex-end;
}

#footer-right {
  text-align: right;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

#signout-container {
  height: 20px;
  text-align: right;
  padding-right: min(27px, 2.7vw);
  padding-bottom: 18px;
}

.signout {
  font-size: 16px;
  opacity: 0.4;
  font-family: Blinker;
  background: none;
  color: white;
  border: none;
  font-weight: 300;
  padding: 0px;
  /* margin-right: 6px; */
  cursor: pointer;
  transition: opacity .45s ease;
}

.signout:hover {
  opacity: 1.0;
}

.copyright, .email {
  opacity: 0.4;
}
.copyright {
  cursor: auto;
  pointer-events: none;
}
.email {
  transition: opacity .45s ease;
}
a {
  text-decoration: none;
  color: inherit;
}
.email:hover {
  opacity: 1.0;
}
.email:visited {
  color: inherit;
}

.photoview {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* animation: fadein 0.25s; */
  align-self: start;
  position: relative;
}

.mainphoto-container {
  background: white;
  border-radius: 13px 0px 13px 13px;
  padding: 8px;
  position: relative;

  /* transition: .45s ease; */
}

.canvas-wrapper {
  /* transition: .45s ease; */
}

canvas {
  /* transition: width .45s ease, height .45s ease; */
  transition: .45s ease;
}

.temp-photo {
  background: teal;
  border-radius: 6px;
  height: 50vh;
}

.previews {
  background: white;
  border-radius: 13px;
  padding: 8px;

  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.preview {
  height: min(170px, calc(4vh + 5vw + 30px));
  background: rgba(0, 0, 0, 0.08);
  flex: 1;
  border-radius: 6px;
  margin-right: 8px;
  overflow: hidden;
  position: relative;

  z-index: 1; /* https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari */
}

.uploading-loader {
  height: calc(4vh + 5vw + 30px);
  overflow: hidden;
  position: relative;
  flex: 1;
  width: 100%;
}

.preview:last-child {
  margin-right: 0px;
}

.preview svg {
  position: absolute;
  left: max(-144px, calc(50% - 7.12vw - 7.12vh - 23px));
  top: max(-164px, calc(50% - 7.5vw - 7.5vh - 25px));
  height: min(498px, calc(15vw + 15vh + 50px));
  width: min(473px, calc(14.25vw + 14.25vh + 47px));
}

.uploading-loader svg {
  animation: fadein 0.5s;
  position: absolute;

  left: max(-344px, calc(50% - 28.48vw - 28.48vh - 92px));
  top: max(-500px, calc(50% - 30vw - 30vh - 100px));
  height: min(1529px, calc(60vw + 60vh + 200px));
  width: min(1450px, calc(57vw + 57vh + 188px));
}

.portrait .uploading-loader svg {
  left: max(-400px, calc(50% - 28.48vw - 28.48vh - 92px));
}

@keyframes sunset {
  0% { cy: 130px; }
  25% { cy: 110px; }
  50% { cy: 200px; }

  0% { cx: 121px; }
  33% { cx: 240px; }
  67% { cx: 70px; }
}

.preview svg path, .uploading-loader svg path {
  fill: #DFDFDF;
  fill-opacity: 1.0;
}

.preview svg ellipse, .uploading-loader svg ellipse{
  fill: #DFDFDF;
  fill-opacity: 1.0;
}

.preview.loading svg ellipse, .uploading-loader svg ellipse {
  animation: sunset 3s cubic-bezier(.42, .0, .58, 1.0) infinite;
}

@keyframes tap {
  0% { opacity: 1.0; }
  20% { opacity: 0.7; }
  100% { opacity: 1.0; }
}

.preview.active {
  animation: tap 0.2s linear;
}

.preview.active::after {
  position: absolute;
  content: '';
  background: red;
  right: 8px;
  top: 8px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  animation: fadein 0.4s ease;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
}

#container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 500px) {
  #container {
    padding-top: 50px;
  }

  #logo, #footer, .photoview {
    width: min(800px, 80vw);
    padding-left: min(27px, 2.7vw);
  }

  #footer {
    padding-bottom: 12px;
    margin-top: 60px;
  }

  #footer-left {
  }

  #footer-right {
    padding-right: min(27px, 2.7vw);
  }
}

/* @media (orientation: landscape) { */
@media (min-width: 500px) {
  .portrait .photoview {
    flex-direction: row;
  }

  .portrait .mainphoto-container {
    flex: 442;
  }

  .portrait .previews {
    flex: 124;
    flex-direction: column;
    margin-top: 0px;
    margin-left: 10px;
  }

  .portrait .preview {
    margin-right: 0px;
    margin-bottom: 8px;
  }

  .portrait .preview:last-child {
    margin-bottom: 0px;
  }
}

@keyframes fadein {
  0% { opacity: 0; }
  5% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes popin {
  0% { transform: scale(0.0) }
  15% { transform: scale(1.5) }
  100% { transform: scale(1.0) }
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  font-size: calc(14px + 1vmin);
  color: white;
}

header .title {
  margin-bottom: 0.5em;
  font-size: calc(12px + 3vmin);
  font-weight: 300;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}

header .title b {
  letter-spacing: -0.02em;
  font-size: calc(16px + 4vmin);
  font-variation-settings: 'wght' 340;
  font-family: 'Josefin Sans';
}

header .description {
  font-family: "Poppins";
  font-size: calc(10px + 1.5vmin);
  margin-top: 0px;

  font-size: calc(8px + 1.6vmin);
  font-weight: 300;
  margin-bottom: 2em;
}

.input-image-view {
  border: 1px solid white;
  margin: 10px;
}

.input-image-view-head {
  width: 100%;
  border-bottom: 3px dotted rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.2);
}

.feedback {
  width: 300px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.radios div {
  text-align: left;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.inputs > * {
  margin-top: 10px;
}

.loading-modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: wait;
  font-size: 42px;
  padding-top: 40%;
  z-index: 9999;
}
/* 
input[type='file'] {
  color: black;
  max-width: calc(150px + 15vmin);
  margin: auto;
} */

.pick-photo {
  padding: 25px;
  background: rgb(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.pick-photo span {
  margin-bottom: 15px;
  color: rgb(253, 228, 226);
}

.input-image {
  max-width: 150px;
}

.canvas-container {
  background: white;
  height: 100vw;
  display: flex;
  align-items: center;
  padding-bottom: calc(15px + 3vw);
  padding-top: calc(10px + 2vw);
  max-height: 70vh;
  max-width: calc(80vh + 10vw);
  margin: auto;
  position: relative;
  justify-content: center;
}

.canvas-wrapper {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  height: 100%;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  transition: background .1s ease;
}

.canvas-wrapper.image-not-loaded {
  min-height: calc(100px + 13vh + 13vw);
}

.canvas-wrapper canvas {
  width: 100%;
  margin: auto;
  animation: fadein 0.5s;
  border-radius: 6px;
}

.canvas-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: red; */
  border-radius: 6px;
  margin: 8px;
  pointer-events: none;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

textarea, input, button {
  padding: 8px;
  font-family: inherit;
  font-size: calc(10px + 1vmin);
}

button {
  font-variation-settings: 'wght' 600;
}

.preview-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content:center;
}

.preview-wrapper canvas {
  position: absolute;
  border-radius: 6px;
  animation: fadein 0.5s;
  align-self: center;

  transition: width 0.15s cubic-bezier(0,1,1,1), height 0.15s cubic-bezier(0,1,1,1);
}

.portrait .preview-wrapper canvas {
  height: auto !important;
  width: 120% !important;
}

.portrait .preview-wrapper canvas:hover {
  width: 130% !important;
}

.landscape .preview-wrapper canvas {
  width: auto !important;
  height: 120% !important;
}

.landscape .preview-wrapper canvas:hover {
  height: 130% !important;
}

.preview-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  pointer-events: none;

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

canvas {
  display: block;
}

#firebaseui_container {
  margin-top: calc(13px + 1.3vmin);
  margin-bottom: calc(12px + 1.2vmin);
}

#firebaseui_container .mdl-card {
  box-shadow: none !important;
}

.firebaseui-idp-button {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: none !important;

}

.firebaseui-idp-google .firebaseui-idp-text-long {
  color: rgba(0, 0, 0, 0.8) !important;
}

.sign-out {
  position: absolute;
  right: 0.6em;
  top: 0.5em;
  background: none;
  color: #fff;
  border: none;
  padding: 2;
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  font-weight: 300;
  font-size: calc(10px + 1.5vmin);
  cursor: pointer;
  animation: fadein 0.5s;
}

.close-button {
  top: calc(1vmin - 5px);
  right: calc(1vmin - 6px);
  position: absolute;
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
}

.close-button svg {
  width: calc(10px + 1vmin);
  height: calc(10px + 1vmin);
}

.login > p {
  padding-top: 2em;
}

.login {
  text-align: center;
  background: white;
  border-radius: 12px;
  animation: fadein 0.5s;
}

.failure-message {
  text-align: center;
  animation: fadein 1.5s;
}

.failure-message a {
  font-weight: bold;
}

input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  box-sizing: border-box;
  z-index: 9999;
}

input[type="file"]:focus {
  outline: none;
}

.custom-file-upload {
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 6px;
  color: black;
  display: flex;
  padding-top: 12px;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.custom-file-upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.custom-file-upload svg {
  opacity: 0.2;
  margin-right: 2px;
}

.drop-text {
  font-weight: 600;
  opacity: 0.25;
  margin-top: 6px;
}

.drop-or {
  font-size: 14px;
  opacity: 0.25;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 20px;
}

.drop-separator {
  height: 1px;
  width: 22px;
  background: rgba(0, 0, 0, 0.5);
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 2px;
}

.file-upload-button {
  background: white;
  padding: 9px 69px 9px 69px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: auto;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.03));
}

.dropping {
  background: rgba(0, 0, 0, 0.15);
}

.download-button {
  background: white;
  position: absolute;
  right: 0px;
  top: -36px;
  height: 38px;
  width: 42px;
  border-radius: 13px 13px 0px 0px;
  display: flex;
  /* z-index: 0; */
}

.download-button.loaded {
  cursor: pointer;
}

.download-button.downloading {
  cursor: unset;
}

.download-button::before {
  background: white;
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  left: -13px;
  top: 23px;
}

.download-button::after {
  background: black;
  position: absolute;
  content: '';
  width: 13px;
  height: 13px;
  left: -13px;
  top: 23px;
  border-radius: 0px 0px 13px 0px;
}

.download-button svg {
  margin-left: 9px;
  margin-top: 10px;
  transition: opacity 0.2s;
  opacity: 0.2;
}

.download-button.loaded svg {
  opacity: 1.0;
}

.download-button.downloading svg {
  opacity: 0.2;
}

.full-canvas {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  z-index: 9999;
  width: 100%;
  margin: auto;
}

.reset-button {
  position: absolute;
  animation: fadein 0.5s;
  z-index: 1;

  bottom: 6px;
  left: 13px;

  cursor: pointer;

  /* top: -28px;
  left: -6px; */

  /* right: -32px;
  bottom: -12px; */

  /* top: -28px;
  right: 42px; */
}

.downloading-overlay {
  background: rgba(128, 128, 128, 0.75);
  position: absolute;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  border-radius: 6px;
  opacity: 0.0;
  transition: opacity .5s ease;
  pointer-events: none;
}

.downloading-overlay.active {
  opacity: 1.0;
}

.downloading-overlay svg {
  width: 60%;
  height: 60%;
  top: 20%;
  left: 20%;
  position: absolute;
}

.downloading-overlay path {
  stroke: rgba(200, 200, 200, 0.5);
}

@keyframes bounce-arrow {
  0% { transform: translate(0px, -2px); }
  80% { transform: translate(0px, 0.5px); }
  100% { transform: translate(0px, -2px); }
}

.downloading-overlay svg path:first-child {
  animation: bounce-arrow 3s ease-out infinite;
}

.ext-link {
  display: none;
}

@media (min-width: 500px) {
  .ext-link {
    display: block;
  }
}

.ext-link {
  margin-bottom: 2px;
  margin-left: 1px;
  width: min(140px, 14vw);
  transition: opacity .45s ease;
  opacity: 0.4;
}

.ext-link:hover {
  opacity: 1.0;
}

.loaded .effectiveness-slider {
  display: flex;
}

.effectiveness-slider {
  position: absolute;
  bottom: 10px;
  right: 0;
  left: 0;
  display: none;
}

.effectiveness-slider input {
  position: relative;
  width: 50%;
  margin: auto;
}
